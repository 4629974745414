import { useState, useMemo, useEffect } from 'react';
import { Grid, Typography, useTheme, Box, Divider } from '@mui/material';
import PingTable from '@/content/Ping/Components/Table';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
      backgroundColor: 'transparent'
    }
  }
});

interface ContinentContentProps {
  continentName: string;
  isIPv6: boolean;
  hostname: string;
  workers: any[];
  data: object;
  onLatencyClick: (data: any) => void;
  onHopClick: (data: any) => void;
  onMapClick: (data: any) => void;
  inputType: string;
  user: object;
  reverseDns: object;
  isRunning: boolean;
  date: any;
}

function ContinentContent({
  continentName,
  workers,
  hostname,
  isIPv6,
  data,
  onLatencyClick,
  onHopClick,
  onMapClick,
  inputType,
  user,
  reverseDns,
  isRunning,
  date
}: ContinentContentProps) {
  const theme = useTheme();
  const [ipReverseArray, setIpReverseArray] = useState({});
  const [pingTime, setPingTime] = useState(new Date().toUTCString());

  const tableData = useMemo(() => {
    const tData = [];

    if (workers.length === 0) {
      return [];
    }
    workers.forEach((w) => {
      const pData = data[w.id];
      if (pData) {
        const lastReply = pData.data[pData.data.length - 1];
        const lastTime = pData.stats.last;
        tData.push({
          id: w.id,
          price: w.price,
          asn: w.asn,
          isp: w.isp,
          hoster: pData.hoster,
          data: pData.data,
          provider: w.location.provider.name,
          affiliate_link: w.location.provider.affiliate_link,
          country: w.location.country,
          city: w.location.city,
          ip: pData.ip,
          worker_ipv4: pData.worker_ipv4,
          hop_count: pData.hop_count,
          http: pData.http,
          seq: pData.stats.seq,
          loss: pData.stats.loss.toFixed(2) + '%',
          latency: lastTime ? Math.round(lastTime * 100) / 100 : '-',
          jitter: pData.stats.jitter.toFixed(2),
          jitterPercentage: Math.round(pData.stats.jitter * 100) / 100,
          min: pData.stats.min.toFixed(2),
          max: pData.stats.max.toFixed(2),
          avg: pData.stats.avg.toFixed(2),
          connectionError: false
        });
      } else if (user && user.is_superuser) {
        tData.push({
          id: w.id,
          price: w.price,
          asn: w.asn,
          isp: w.isp,
          hoster: '-',
          data: [],
          provider: w.location.provider.name,
          affiliate_link: w.location.provider.affiliate_link,
          country: w.location.country,
          city: w.location.city,
          ip: '',
          worker_ipv4: '',
          hop_count: '-',
          http: '-',
          seq: '-',
          loss: '-',
          latency: '-',
          jitter: '-',
          jitterPercentage: '-',
          min: '-',
          max: '-',
          avg: '-',
          connectionError: true
        });
      }
    });

    return tData;
  }, [workers, data, ipReverseArray]);

  const httpStatus200Count = useMemo(() => {
    return Object.entries(data).filter(([_, data]) => data.http === 200).length;
  }, [data]);

  const packetLossCount = useMemo(() => {
    if (Object.keys(data).length === 0) return 0;

    return (
      Object.entries(data)
        .map(([_, data]) => data.stats.loss)
        .reduce((partialSum, a) => partialSum + a, 0) / Object.keys(data).length
    );
  }, [data]);

  // const totalPrice = () => {
  //   let total = 0;
  //   workers.map((w) => {
  //     total += w.price;
  //   });
  //   return total.toFixed(2);
  // };

  useEffect(() => {
    setPingTime(date ? new Date(date).toUTCString() : new Date().toUTCString());
  }, [date]);

  useEffect(() => {
    const element = document.getElementById('submitScroll');
    element.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Box id={'submitScroll'} key={continentName}>
      <Grid container sx={{ mb: 1, mt: 2 }} alignItems="end">
        <Grid item xs={12} md={6}>
          <Typography sx={{ alignSelf: 'end' }} variant={'h3'}>
            {continentName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              textAlign: { xs: 'left', md: 'right' },
              paddingBottom: 0.3,
              alignSelf: 'end',
              typography: { xs: 'subtitle1', md: 'h5' }
            }}
          >
            UTC: {pingTime.split(' ').slice(0, 5).join(' ')}
          </Typography>
        </Grid>
        <Divider
          variant="fullWidth"
          orientation="horizontal"
          sx={{ width: '100%', paddingBottom: 0.5, marginBottm: 3 }}
        />

        {/* <Typography variant={'h3'}>Total Price: ${totalPrice()}</Typography> */}
      </Grid>

      {/* <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6} lg={3}>
          <DataCard
            title={'Workers'}
            description={{
              first: '',
              second: user && user.is_superuser ? ' ' : null
            }}
            textColor={'green'}
            color={theme.colors.gradients.black2}
            icon={<StorageIcon fontSize="small" />}
            data={{
              first: workers.length,
              second:
                user && user.is_superuser
                  ? workers.length - Object.keys(data).length
                  : null
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DataCard
            title={'ICMP'}
            description={{ first: 'No. of workers that received a reply' }}
            icon={<SettingsInputAntennaIcon fontSize={'small'} />}
            color={theme.colors.gradients.pink2}
            data={{ first: Object.keys(data).length }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DataCard
            title={'Packet Loss'}
            description={{ first: '% of packets lost' }}
            icon={<ArrowDownwardIcon fontSize={'small'} />}
            color={theme.colors.gradients.blue4}
            data={{ first: parseInt(packetLossCount) }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DataCard
            title={'HTTP Status'}
            description={{
              first: 'No. of workers that received status 200(OK)'
            }}
            icon={<CheckIcon fontSize={'small'} />}
            color={theme.colors.gradients.green2}
            data={{ first: httpStatus200Count }}
          />
        </Grid>
      </Grid> */}

      <PingTable
        workers={workers}
        onLatencyClick={onLatencyClick}
        onHopClick={onHopClick}
        onMapClick={onMapClick}
        isIPv6={isIPv6}
        hostname={hostname}
        data={tableData}
        inputType={inputType}
        user={user}
        reverseDns={reverseDns}
        isRunning={isRunning}
      />
    </Box>
  );
}

export default ContinentContent;
