import { useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import useScreenCapture from '@/hooks/useScreenshot';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import {
  Typography,
  Box,
  DialogTitle,
  DialogContent,
  Button,
  Dialog,
  CircularProgress
} from '@mui/material';

const WorkerDialogPropTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  header: PropTypes.shape({
    provider: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string
  }).isRequired,
  children: PropTypes.element.isRequired
};
type WorkerDialogProps = InferProps<typeof WorkerDialogPropTypes>;

function WorkerDialog({
  open,
  title,
  onClose,
  children,
  header
}: WorkerDialogProps) {
  const screenshotRef = useRef(null);
  const screenshot = useScreenCapture();

  // screenshot
  const handleScreenshot = (ref, serviceType) => {
    screenshot.getImage(serviceType, ref);
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleScreenshot(screenshotRef, title)}
          >
            {!screenshot.isLoading ? (
              <CameraAltIcon />
            ) : (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            )}
          </Button>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h2" sx={{ flex: 1, textAlign: 'center' }}>
              {title}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography sx={{ mx: 2, fontWeight: 'bold' }} variant="body1">
                Provider:{' '}
                <span style={{ fontWeight: 'normal' }}>{header.provider}</span>
              </Typography>
              <Typography sx={{ mx: 2, fontWeight: 'bold' }} variant="body1">
                Country:{' '}
                <span style={{ fontWeight: 'normal' }}>{header.country}</span>
              </Typography>
              <Typography sx={{ mx: 2, fontWeight: 'bold' }} variant="body1">
                City:{' '}
                <span style={{ fontWeight: 'normal' }}>{header.city}</span>
              </Typography>
            </Box>
          </Box>

          <Button variant="contained" onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ textAling: 'center', padding: 0 }}
        ref={screenshotRef}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

WorkerDialog.propTypes = WorkerDialogPropTypes;

WorkerDialog.defaultProps = {};

export default WorkerDialog;
