import React, { memo, useState, useEffect } from 'react';
import {
  Box,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination,
  TableSortLabel
} from '@mui/material';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
const PingTableRow = dynamic(() => import('./TableRow'), { ssr: false });

interface Data {
  id: number;
  price: number;
  provider: string;
  asn: string;
  isp: string;
  city: string;
  ip: string;
  seq: number;
  http: number;
  hop_count: number;
  latency: number;
  loss: number;
  jitter: number;
  jitterPercentage: number;
  actions: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface PingTableProps {
  workers: Array<any>;
  data: Array<any>;
  hostname: string;
  isIPv6: boolean;
  onLatencyClick: () => void;
  onHopClick: () => void;
  onMapClick: () => void;
  inputType: string;
  user: object;
  reverseDns: object;
  isRunning: boolean;
}

function PingTable(
  {
    workers,
    data,
    hostname,
    isIPv6,
    onLatencyClick,
    onHopClick,
    onMapClick,
    inputType,
    user,
    reverseDns,
    isRunning
  }: PingTableProps = {
    workers: [],
    data: {},
    hostname: '',
    isIPv6: false,
    inputType: '',
    user: {},
    reverseDns: {}
  }
) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('city');
  const router = useRouter();

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const paginatedResults = data
    .slice()
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    if (router.query.continent && typeof router.query.continent == 'string') {
      setRowsPerPage(1000);
    }
  }, [router.query]);

  return (
    <TableContainer>
      <Table size="small" padding="none">
        {/* TABLE HEAD START */}
        <TableHead>
          <TableRow>
            {/* Worker ID */}
            {user && user.is_superuser && (
              <TableCell
                sx={{
                  width: '20px',
                  pl: 1,
                  pr: { xs: 1, md: 4 },
                  position: 'relative'
                }}
              >
                ID
                <TableSortLabel
                  sx={{ position: 'absolute', top: 3 }}
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={createSortHandler('id')}
                />
              </TableCell>
            )}

            {/* Worker IP (only for superusers) */}
            {user && user.is_superuser && (
              <TableCell
                sx={{
                  width: '100px',
                  pl: 1,
                  pr: { xs: 1, md: 4 },
                  position: 'relative',
                  whiteSpace: 'nowrap',
                  display: {
                    xs: 'none',
                    md: 'table-cell'
                  }
                }}
              >
                Worker IP
                <TableSortLabel
                  sx={{ position: 'absolute', top: 3 }}
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={createSortHandler('id')}
                />
              </TableCell>
            )}

            {/* Provider */}
            <TableCell
              sx={{
                display: {
                  xs: 'none',
                  md: 'table-cell'
                },
                width: '170px'
              }}
            >
              Provider
            </TableCell>
            <TableCell
              sx={{
                display: {
                  xs: 'none',
                  lg: 'table-cell'
                },
                width: '170px'
              }}
            >
              ISP
            </TableCell>

            {/* ASN */}
            <TableCell
              sx={{
                display: {
                  xs: 'none',
                  lg: 'table-cell'
                },
                width: '85px'
              }}
            >
              ASN
            </TableCell>

            {/* Location */}
            <TableCell sx={{ width: 'auto' }}>Location</TableCell>

            {/* Resolved IP */}
            <TableCell
              align="center"
              sx={{
                display: {
                  xs: 'none',
                  xl: 'table-cell'
                },
                width: '150px',
                whiteSpace: 'nowrap'
              }}
            >
              Resolved Ip
            </TableCell>

            {/*  Hoster */}
            <TableCell
              align="center"
              sx={{
                display: {
                  xs: 'none',
                  xl: 'table-cell'
                },
                width: '150px',
                whiteSpace: 'nowrap'
              }}
            >
              Hoster
            </TableCell>

            {/* Sent */}
            <TableCell
              align="center"
              sx={{
                display: {
                  xs: 'none',
                  lg: 'table-cell'
                },
                width: '50px',
                px: 1
              }}
            >
              Sent
            </TableCell>

            {/* HTTP Status */}
            {inputType === 'domain' && (
              <TableCell
                align="center"
                sx={{
                  display: {
                    xs: 'none',
                    md: 'table-cell'
                  },
                  width: '50px',
                  px: 1
                }}
              >
                HTTP
              </TableCell>
            )}

            {/* Hop Count */}
            <TableCell
              align="center"
              sx={{
                display: {
                  xs: 'none',
                  md: 'table-cell'
                },
                width: '50px',
                pl: 1,
                pr: 2,
                position: 'relative'
              }}
            >
              Hops
              <TableSortLabel
                sx={{ position: 'absolute', top: 3 }}
                active={orderBy === 'hop_count'}
                direction={orderBy === 'hop_count' ? order : 'asc'}
                onClick={createSortHandler('hop_count')}
              />
            </TableCell>

            {/* Latency */}
            <TableCell
              align="center"
              sx={{
                width: '50px',
                pl: 1,
                pr: 2,
                position: 'relative'
              }}
            >
              Latency
              <TableSortLabel
                sx={{ position: 'absolute', top: 3 }}
                active={orderBy === 'latency'}
                direction={orderBy === 'latency' ? order : 'asc'}
                onClick={createSortHandler('latency')}
              />
            </TableCell>

            {/* Loss */}
            <TableCell
              align="center"
              sx={{
                width: '100px',
                pl: 1,
                pr: 2,
                position: 'relative'
              }}
            >
              Loss
              <TableSortLabel
                sx={{ position: 'absolute', top: 3 }}
                active={orderBy === 'loss'}
                direction={orderBy === 'loss' ? order : 'asc'}
                onClick={createSortHandler('loss')}
              />
            </TableCell>

            {/* Jitter */}
            <TableCell
              align="center"
              sx={{
                display: {
                  xs: 'none',
                  sm: 'table-cell'
                },
                width: '120px',
                pl: 2,
                pr: 4,
                position: 'relative'
              }}
            >
              <Tooltip
                arrow
                placement="top"
                title="Jitter expresses the fluctuations of the ping value and, therefore, the stability of the connection. The result ​​(in milliseconds) should be as low as possible. The higher the jitter value displayed in the test, the worse your internet connection stability is."
              >
                <Box display="flex" alignItems="center" flexDirection="row">
                  <InfoTwoToneIcon sx={{ fontSize: '16px' }} />
                  &nbsp;Jitter
                </Box>
              </Tooltip>
              <TableSortLabel
                sx={{ position: 'absolute', top: 3, right: 0 }}
                active={orderBy === 'jitterPercentage'}
                direction={orderBy === 'jitterPercentage' ? order : 'asc'}
                onClick={createSortHandler('jitterPercentage')}
              />
            </TableCell>

            {/* Actions */}
            <TableCell
              align="center"
              sx={{
                width: '50px',
                px: {
                  lg: 2
                }
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'inline-block'
                  }
                }}
              >
                Actions
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>

        {/* TABLE BODY START */}
        <TableBody>
          {paginatedResults.map((d) => (
            <PingTableRow
              key={d.id}
              isIPv6={isIPv6}
              hostname={hostname}
              data={d}
              onLatencyClick={onLatencyClick}
              onHopClick={onHopClick}
              onMapClick={onMapClick}
              inputType={inputType}
              user={user}
              isRunning={isRunning}
              reverseDnsData={reverseDns[d.ip]}
            />
          ))}
          <TableRow>
            <TablePagination
              labelRowsPerPage=""
              rowsPerPageOptions={[]}
              colSpan={20}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              style={{ borderBottom: 'none' }}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page'
                },
                native: true
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PingTable;
