import dynamic from 'next/dynamic';
import PropTypes, { InferProps } from 'prop-types';
import WorkerDialog from './WorkerDialog';

const PingTerminal = dynamic(() => import('@/components/pingTerminal'), {
  ssr: false
});

const PingDialogPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  worker: PropTypes.shape({
    provider: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  hostname: PropTypes.string.isRequired,
  isIPv6: PropTypes.bool.isRequired
};
type PingDialogProps = InferProps<typeof PingDialogPropTypes>;

function PingDialog({
  open,
  onClose,
  worker,
  hostname,
  isIPv6
}: PingDialogProps) {
  return (
    <WorkerDialog title={'PING'} open={open} onClose={onClose} header={worker}>
      <PingTerminal workerId={worker.id} hostname={hostname} isIPv6={isIPv6} />
    </WorkerDialog>
  );
}

PingDialog.propTypes = PingDialogPropTypes;

PingDialog.defaultProps = {
  onClose: () => {}
};

export default PingDialog;
