import dynamic from 'next/dynamic';
import PropTypes, { InferProps } from 'prop-types';
import WorkerDialog from './WorkerDialog';

const MtrTerminal = dynamic(() => import('@/components/mtrTerminal'), {
  ssr: false
});

const MTRDialogPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  worker: PropTypes.shape({
    provider: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  hostname: PropTypes.string.isRequired,
  isIPv6: PropTypes.bool.isRequired
};
type MtrDialogProps = InferProps<typeof MTRDialogPropTypes>;

function MTRDialog({
  open,
  onClose,
  worker,
  hostname,
  isIPv6
}: MtrDialogProps) {
  return (
    <WorkerDialog title={'MTR'} open={open} onClose={onClose} header={worker}>
      <MtrTerminal workerId={worker.id} hostname={hostname} isIPv6={isIPv6} />
    </WorkerDialog>
  );
}

MTRDialog.propTypes = MTRDialogPropTypes;

MTRDialog.defaultProps = {
  onClose: () => {}
};

export default MTRDialog;
