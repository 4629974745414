import dynamic from 'next/dynamic';
import PropTypes, { InferProps } from 'prop-types';
import WorkerDialog from './WorkerDialog';

const Map = dynamic(() => import('@/content/Ping/Components/Map'), {
  ssr: false
});

const MapDialogPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.object.isRequired,
  header: PropTypes.shape({
    provider: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string
  }).isRequired,
  workers: PropTypes.array,
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number
};

type MapDialogProps = InferProps<typeof MapDialogPropTypes>;

function MapDialog({
  open,
  onClose,
  data,
  zoom,
  center,
  workers,
  header
}: MapDialogProps) {
  return (
    <WorkerDialog title={'Map'} open={open} onClose={onClose} header={header}>
      <Map
        workers={workers}
        data={data}
        zoom={zoom}
        tooltipOpen={true}
        center={center}
      />
    </WorkerDialog>
  );
}

MapDialog.propTypes = MapDialogPropTypes;

MapDialog.defaultProps = {
  onClose: () => {}
};

export default MapDialog;
